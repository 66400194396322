<template>
  <div class="labeled-icons">
    <UiHeader
      v-if="header"
      class="labeled-icons__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
    />

    <div
      class="labeled-icons__wrapper row-down flex-wrap"
      :class="labeledIconsWrapperSize"
    >
      <div
        v-for="image in flatGallery"
        :key="image.id"
        class="labeled-icons__item col flex-center"
      >
        <UiImg
          :image="image"
          class="labeled-icons__icon"
          use-image-size
          decorative
        />

        <T3HtmlParser
          v-if="image.properties.title"
          :tag="image.properties.description ? 'h3' : 'div'"
          class="labeled-icons__title"
          :content="image.properties.title"
        />

        <T3HtmlParser
          v-if="image.properties.description"
          class="labeled-icons__desc grow"
          :content="image.properties.description"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiCeTextGalleryProps } from '~/types'
import { UiHeader, UiImg } from '~ui/components'
import useFlatGallery from '~ui/composables/useFlatGallery'

const props = defineProps<UiCeTextGalleryProps>()

const flatGallery = useFlatGallery(props)

const labeledIconsWrapperSize = computed(() => {
  return props.gallery?.count?.columns === 3
    ? 'labeled-icons__wrapper--small'
    : ''
})
</script>

<style lang="scss">
.labeled-icons {
  margin: spacing(lg) auto;

  &__wrapper {
    margin-top: rem(5px);

    @include media-query(sm) {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-query(md) {
      grid-template-columns: repeat(4, 1fr);

      &--small {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__item {
    margin: 0 auto;
    padding: em(28px) em(8px);
    text-align: center;
    width: 48%;

    @include media-query(sm) {
      width: auto;
    }
  }

  &__icon {
    object-fit: contain;
  }

  &__title {
    font-size: rem(20px);
    font-weight: 600;
    margin-block: rem(15px) auto;
  }

  &__desc {
    line-height: 1.2;
    font-size: rem(16px);
    margin-top: rem(12px);
  }
}
</style>
